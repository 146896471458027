import { render, staticRenderFns } from "./IntelligentCulturalTourism.vue?vue&type=template&id=7416af36&scoped=true&"
import script from "./IntelligentCulturalTourism.vue?vue&type=script&lang=js&"
export * from "./IntelligentCulturalTourism.vue?vue&type=script&lang=js&"
import style0 from "./IntelligentCulturalTourism.vue?vue&type=style&index=0&id=7416af36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7416af36",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBanner,VCard,VCardText,VCardTitle,VCol,VContainer,VImg,VProgressCircular,VRow})
