<template>
  <div>
    <v-banner class="lavBanner" color="#f5f8fd" single-line height="400">
      <v-img src="https://h5.ophyer.cn/official_website/banner/bannerIntelligentCulturalTourism400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex max-width-1200">
          <v-card color="transparent" flat>
            <div class="d-flex flex-no-wrap">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  智慧文旅解决方案
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px">
                  通过AR/VR等技术化手段，线下发展新一代沉浸式体验型文旅消费内容<br>推动旅游景区向数字化转型升级
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 文旅行业解决方案 -->
    <v-container>
      <div class="public-title" style="margin:82px 0 25px 0">
        <p>SOLUTION</p>
        <div>
          <span>文旅行业解决方案</span>
          <span>技术+创意让景区焕发全新的活力</span>
        </div>
      </div>
      <v-container class="d-flex justify-center">
        <v-row class="max-width-1200">
          <v-col cols="3" v-for="(card,index) in data.cards" :key="index">
            <v-card elevation="0">
              <div class="d-flex justify-center">
                <v-img
                      :src="card.src"
                      class="white--text align-end flex-grow-0 rounded-lg"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="160"
                      width="260">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          size="80"
                          color="red lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                      <v-card-title
                        class="justify-left white--text font-size-20"
                        v-text="card.title"
                      ></v-card-title>
                </v-img>
              </div>
          <div class="d-flex justify-center">
            <v-card-text class="justify-center mt-2 font-size-14 px-0" style="width:260px;color:#666">{{ card.content }}</v-card-text>
          </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!-- 文旅行业标准产品 -->
    <v-container style="padding-bottom: 60px;">
      <div class="public-title" style="margin:34px 0 46px 0">
        <p>STANDARD PRODUCT</p>
        <div>
          <span>文旅行业标准产品</span>
          <span>技术+游戏化的产品形式，为景区文化的宣传及经济活力的提升助力</span>
        </div>
      </div>
      <v-container>
        <div class="d-flex justify-center align-center">
            <v-img
              class="flex-grow-0 rounded-xl"
              height="260"
              width="400"
              src="https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-5-new.png"
            >
            <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
            </template>
            </v-img>
            <div style="margin-left:83px">
              <v-card-title class="font-size-24 font-weight-bold py-0 px-0">AR活动打卡游戏</v-card-title>
              <ul class="mt-5 ulStyle">
                <li>针对景区、活动展览开发制作的线上线下一体沉浸式游览交互互动的小程序游戏</li>
                <li>针对不同旅游人群特点为景区设计旅游路线规划</li>
                <li>利用互动打卡游戏设置打卡点，为景区指定商家引流，促进商家消费以及互动抽奖提升景区二次传播</li>
                <li>利用AR技术针对景区为修复文物进行场景复原，传播景区历史文化</li>
              </ul>
            </div>
        </div>
      </v-container>
    </v-container>
    <!-- 客户案例 -->
    <!--<v-container class="mt-16" style="height:655px;background:#f5f8fd">-->
    <!--  <div class="d-flex justify-center" style="width:100%;height:100%">-->
    <!--    <div style="width:100%">-->
    <!--      <div class="public-title" style="margin:55px 0 28px 0;">-->
    <!--        <p>CUSTOMER CASE</p>-->
    <!--        <div>-->
    <!--          <span>客户案例</span>-->
    <!--          <span>技术+创意落地展示</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-container style="max-width:1120px;">-->
    <!--        <v-row>-->
    <!--            <v-col v-for="(card, index) in anli" :key="index" cols="3">-->
    <!--                <v-card-->
    <!--                  class="mx-auto item-card"-->
    <!--                  max-width="240"-->
    <!--                  max-height="410"-->
    <!--                  color="transparent"-->
    <!--                  @click="goCaseDetail(card)"-->
    <!--                >-->
    <!--                  <v-img-->
    <!--                    max-height="340"-->
    <!--                    :src="card.coverImg"-->
    <!--                    :aspect-ratio="9/16"-->
    <!--                  >-->
    <!--                  <template v-slot:placeholder>-->
    <!--                          <v-row class="fill-height ma-0" align="center" justify="center">-->
    <!--                            <v-progress-circular-->
    <!--                              indeterminate-->
    <!--                              size="80"-->
    <!--                              color="red lighten-5"-->
    <!--                            ></v-progress-circular>-->
    <!--                          </v-row>-->
    <!--                  </template>-->
    <!--                  </v-img>-->
    <!--                  <v-card-text class="font-size-14 font-weight-bold" v-text="card.itemTitle"></v-card-text>-->
    <!--                </v-card>-->
    <!--            </v-col>-->
    <!--        </v-row>-->
    <!--      </v-container>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</v-container>-->
  </div>
</template>

<script>
let _this;
import textData from '@/static/textData.js' // 合作案例
import lavData from '@/static/lavData.js'
import qs from 'qs'
export default {
  name: "IntelligentCulturalTourism",
  data: () => ({
    imgLoad: false,
    windowHight: window.innerHeight / 2,
    data:lavData.IntelligentCulturalTourism,
    anli:[textData.anli.qiaojiadayuan,textData.anli.youlun,textData.anli.haiyangguan,textData.anli.kunyuanyimeng]
  }),
  mounted() {
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: () => {
      _this.imgLoad = true;
      console.log("ewf");
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  },
};
</script>

<style lang="scss" scoped>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 1px 14px 0px rgb(0 60 255 / 8%) !important;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.04);
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &::after {
      content: "";
      background-image: url("./../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
}
.fontColor{
  color:#666!important;
  opacity: 1;
}
.ulStyle{
  li{
    list-style:disc;
    line-height:26px;
    color: #666;
  }
}
</style>